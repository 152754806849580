<template>
  <div id='cart-screen'>
    <div class="container hungarospa-container">
      <header>
        <h1 class="title">{{ $t('pages.cart.title') }}</h1>
        <error-toast
          :isVisible="errorToast.isVisible"
          :text="$t(errorToast.text)"
        />
      </header>
      <div class="content-section hungarospa-two-column-layout">
        <div class="column-left">
          <cart-module
            :isTitleVisible="false"
            :animateOnChange="false"
            :isBtnHidden="true"
          />
        </div>
        <div class="column-right">
          <transition name="info-collapse" >
            <cart-mergeable-info v-if="isCartMergeable" :isBtnLoading="isMergeBtnLoading" @btnClicked="handleMergeBtnClick" />
          </transition>
          <cart-summary />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import CartModule from '@/components/shared/modules/carts/Cart';
import CartSummary from '@/components/shared/modules/carts/CartSummary';

export default {
  name: 'CartScreen',
  props: {},
  components: {
    CartModule,
    'cart-mergeable-info': () => import('@/components/shared/modules/carts/CartMergeableInfo'),
    CartSummary,
    'error-toast': () => import('@/components/shared/elements/toasts/ErrorToast'),
  },
  data: () => ({
    isMergeBtnLoading: false,
    errorToast: {
      isVisible: false,
      text: 'general.errors.ticketsQuantity',
    },
  }),
  created() {},
  computed: {
    ...mapGetters({
      getCurrentCart: 'cart/getCurrentCart',
    }),
    isCartMergeable() {
      if (this.getCurrentCart) {
        return this.getCurrentCart.mergeable;
      }

      return false;
    },
  },
  methods: {
    ...mapActions({
      switchCartItems: 'cart/switchCartItems',
    }),
    handleMergeBtnClick() {
      this.isMergeBtnLoading = true;

      this.switchCartItems(this.getCurrentCart._id).then(() => {
        this.isMergeBtnLoading = false;
      });
    },
  },
};
</script>

<style lang='scss' scoped>
$errorToastMaxWidth: 550px;

@keyframes collapse {
  0% {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 1000px;
    opacity: 1;
  }
}

.info-collapse-enter-active {
  animation: collapse 0.7s;
}

.info-collapse-leave-active {
  animation: collapse 0.7s reverse;
}

#cart-screen {
  padding: 40px 0 85px;
}

header {
  margin: 0 0 40px;

  .title {
    @include titleMixin();
    font-size: 40px;
    line-height: 1;

    @media screen and (max-width: $breakpointDownSm) {
      font-size: 32px;
    }
  }
}

.content-section {
  max-width: 955px;
  margin: 0 auto;
}

.hungarospa-two-column-layout {
  @media screen and (max-width: $breakpointDownMd) {
    column-gap: 15px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    grid-template-columns: 1fr;
    row-gap: 25px;
  }

  .column-right {
    width: 350px;

    @media screen and (max-width: $breakpointDownMd) {
      width: 280px;
    }

    @media screen and (max-width: $breakpointDownSm) {
      width: 100%;
    }
  }
}

.cart-module {
  width: 100%;

  &::v-deep {
    .cart-content {
      padding: 25px 35px;

      @media screen and (max-width: $breakpointDownMd) {
        padding-left: 20px;
        padding-right: 20px;
      }

      @media screen and (max-width: $breakpointDownXs) {
        background-color: transparent;
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .delete-col .delete-btn {
      @media screen and (max-width: $breakpointDownXs) {
        margin-left: 10px;
      }
    }

    .date {
      padding-left: 0;
    }

    .date,
    .cart-item,
    .cart-items-group {
      margin: 0 0 15px;
    }

    .summary-row {
      margin-top: 20px;
      padding-left: 25px;
      padding-right: 35px;
    }
  }
}

.error-toast {
  max-width: $errorToastMaxWidth;
  margin: 15px auto 0;
}
</style>
